.card {
	width: 21%;
  float: left;
  height: 280px;
	background-color: #ffffff;
	border-radius: 10px;
	color: #2a2d36;
	margin: 25px;
	margin-right: 12px;
	text-align: center;
	box-shadow: 0 13px 10px rgba(36, 114, 175, 0.07);
}

.card:hover {
	transition: transform 0.6s;
	transform-style: preserve-3d;
	-webkit-box-shadow: 10px 7px 11px -5px rgba(115, 115, 115, 0.18);
	-moz-box-shadow: 10px 7px 11px -5px rgba(115, 115, 115, 0.18);
	box-shadow: 10px 7px 11px -5px rgba(115, 115, 115, 0.18);
	border: 1px solid #533278;
	transform: scale(1.02);
}

.cardContainer{
	height: 60px;
}

.iconImg{
  width: 30px;
  display: inline !important;
}

.headLabel{
	font-size: 16px;
	font-weight: 700;
	padding-top: 20px;
}

.uploadDiv {
	width: 100%;
	padding-top: 30px;
}

[type="file"] {
	height: 0;
    overflow: hidden;
    width: 0;
}

[type="file"] + label {
	background-color: #533278;
	border: none;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: 'Rubik', sans-serif;
	font-size: inherit;
	font-weight: 500;
	margin-bottom: 1rem;
	outline: none;
	padding: 1rem 20px;
	position: relative;
	transition: all 0.3s;
	vertical-align: middle;
}

.uploadBtn{
  background-color: #F5887F;
	border: none;
	padding: 10px 30px;
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	cursor: pointer;
}

.filename{
	height: 25px;
}

.loader {
    color: black;
    font-size: 18px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 5px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  
  @-webkit-keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
        -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
        -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
        -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
        -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
        -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
        -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .remove{
    color: red;
  }

  .remove:hover{
    text-decoration: underline;
    cursor: pointer;
  }
@font-face {
  font-family: Roboto-Medium;
  src: url(../src/assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(../src/assets/fonts/Roboto-Regular.ttf);
}

body {
  margin: 0;
  font-family: Roboto-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto-Regular;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-header {
  background-color: #420045;
  -webkit-box-shadow: 0 0 28px 0 rgba(86, 61, 124, 0.13);
  box-shadow: 0 0 28px 0 rgba(86, 61, 124, 0.13);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 4.125rem;
  position: relative;
  z-index: 1000;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  width: 100%;
  padding: 15px;
  position: fixed;
}

.toggleSideIcon {
  cursor: pointer;
  color: white;
}

.moreIcon {
  cursor: pointer;
}

.side-navigation-panel {
  text-align: left !important;
}

.side-navigation-panel-select-inner-option {
  padding: 0.7rem 2.5rem !important;
}

.clearfix {
  clear: both;
}

.mainBody {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
  cursor: pointer;
  border: none;
  background-color: #f6f6e6;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  min-height: 400px;
}

.pageheading {
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #543379;
  padding: 10px 18px;
  float: left;
  font-family: Roboto-Regular;
}

.headerDivReport {
  float: right;
  margin: 20px 5px;
}

#reportDRPContainerYTD .daterangepicker {
  border: none !important;
  top: 60px !important;
  left: 50% !important;
}

#reportDRPContainerWallet .daterangepicker {
  border: none !important;
  top: 60px !important;
  left: 50% !important;
}

#reportDRPContainerContribution .daterangepicker {
  border: none !important;
  top: 60px !important;
  left: 50% !important;
}

#reportDRPContainerAggregate .daterangepicker {
  border: none !important;
  top: 60px !important;
  left: 50% !important;
}

.filterByDateButton {
  font-size: 15px;
  height: 40px;
  font-weight: 400;
  /* border-radius: 5px; */
  background-color: #fff;
  color: #787885;
  border: 1px solid #fff;
  margin: 8px 17px;
  float: right;
  padding: 10px;
  font-family: Roboto-Regular;
  padding-left: 5px;
  min-width: 225px;
  border-bottom: 1px solid #787885;
}

.filterByDateButton:focus {
  border: 2px solid #f5887f;
  outline: 0px auto;
}

.filterByDateButton:hover {
  background-color: #fdcf85;
  border: 1px solid #fdcf85;
  outline: 0px auto;
}

.channelFilterPosition {
  position: absolute;
  top: 0px;
  left: 310px;
}
.channelFilterPositionNeed {
  position: absolute;
  /* right: 145px; */
  right: 25px;
}

.dateFilterPosition {
  position: absolute;
  left: 540px;
  top: 0px;
}

.dateFilterPositionNeed {
  position: absolute;
  right: 295px;
  top: 12px;
}

.ytdDateFilterPosition {
  position: absolute;
  right: 160px;
  top: 12px;
}

.channelFilterPositionTPRule {
  position: absolute;
  top: 72px;
  right: 165px;
}

.channelFilterPositionNeeds {
  position: absolute;
  top: 65px;
  right: 145px;
}

.householdExportPosition {
  position: absolute;
  right: 165px;
}

.filterHouseholdQuerySelectPosition {
  margin: 8px 0;
}

.householdTopDiv {
  position: absolute;
  right: 170px;
  top: 72px;
}

option:focus {
  background-color: red;
}

.dropdown {
  float: left;
}

.loader {
  color: black;
  font-size: 30px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.loaderDwnld {
  color: black;
  /* font-size: 30px; */
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0px auto;
  position: absolute;
  right: 160px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tableBody {
  width: 100%;
  overflow-y: auto;
  /* overflow-x: hidden; */
  margin: 0 auto;
  font-family: Roboto-Regular;
}

.tableInnerDiv {
  width: 100%;
  padding-top: 12px;
  font-family: Roboto-Regular;
}

.modal-dialog {
  width: 65%;
  margin: 30px auto;
  border-radius: 10px;
}

.modalHeading {
  padding: 20px;
  text-align: left;
  font-size: 24px;
  color: #5f2161;
  font-weight: 600;
  background-color: #f6f6f6;
  border-radius: 10px;
}
.modalFooter {
  padding: 20px;
  text-align: right;
  font-size: 24px;
  font-weight: 600;
  background-color: #f6f6f6;
  border-radius: 10px !important;
}

.modalContentDiv {
  padding: 10px 20px;
}
.tableModalHeading {
  background-color: #f6f6e6;
}
.tableModalContentDiv {
  background-color: #f6f6e6;
}

.modalContentHead {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 5px;
  float: left;
}

.modalContentInput {
  width: 100%;
  height: 40px;
  padding: 12px;
  background-color: #e2e8f0;
}

.searchInput {
  float: right;
  margin: 8px 0px;
  border-bottom: 1px solid #787885;
  /* background-image: url('assets/images/icon.png'); */
  background-position: 190px 8px;
  background-color: #fff;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding: 9px 8px 5px 8px;
  transition: width 0.4s ease-in-out;
  font-family: Roboto-Regular;
  min-width: 225px;
}

.modalBtn {
  background-color: #f5887f;
  border: none;
  padding: 5px 14px;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  margin-right: 40px;
}

.modalBtn:hover {
  background-color: #fdcf85;
}

.modalImg {
  width: 50%;
  height: 200px;
  object-fit: contain;
  margin-top: 10px;
}

.dialogWidth {
  max-width: 98% !important;
  width: 98% !important;
}

.dialogEmpWidth {
  max-width: 40% !important;
}

.search-label {
  float: left;
}

.react-bs-table-csv-btn {
  position: absolute;
  right: 30px;
  background-color: #f5887f !important;
  font-weight: 500 !important;
  color: #fff !important;
  height: 40px !important;
  font-family: Roboto-Regular !important;
  min-width: 140px;
}

.react-bs-table-csv-btn:hover {
  background-color: #fdcf85 !important;
}

.buttonSymbol {
  margin-bottom: 3%;
  margin-right: 10px;
  float: left;
}

.tableBtnInside {
  background-color: #f5887f;
  border: none;
  padding: 5px 14px;
  border-radius: 6px;
  color: white;
  font-size: 11px;
  font-weight: 700;
  margin: 2px;
  min-width: 120px;
}

.tableBtnInside:hover {
  background-color: #fdcf85;
}

.page-item.active .page-link {
  background-color: #fdcf85 !important;
  border-color: #fdcf85 !important;
  color: #fff !important;
}

.page-link {
  color: #543379 !important;
}

.dropdown-toggle {
  background-color: #f5887f !important;
  border-color: #f5887f !important;
  color: #fff !important;
  outline: 0px auto;
}

.dropdown-toggle:hover {
  background-color: #fdcf85 !important;
  border-color: #fdcf85 !important;
  color: #fff !important;
}

:root {
  --amplify-primary-color: #543379;
  --amplify-primary-tint: #543379;
  --amplify-primary-shade: #543379;
}

.daterangepicker {
  top: 200px !important;
  left: 501px !important;
  width: 630px !important;
}

@media screen and (max-width: 1350px) {
  .daterangepicker {
    top: 200px !important;
    left: 401px !important;
    width: 630px !important;
  }
}

.daterangepicker.opensright:before {
  left: 499px !important;
}

.daterangepicker.opensright:after {
  left: 500px !important;
}

.daterangepicker .ranges li.active {
  background-color: #543379 !important;
}

.btn-primary {
  color: #fff;
  background-color: #543379 !important;
  border-color: #543379 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #543379 !important;
}

.noDataCls {
  margin-top: 100px;
}

.table th,
.table td {
  padding: 8px 10px !important;
  background-color: white;
}
th {
  border-bottom: 2px solid #8c827a !important;
}
td {
  border-bottom: 0.5px solid #8c827a !important;
  padding-top: 12px !important;
}
.react-bootstrap-table {
  overflow-y: auto;
}
.modal-content-bg {
  background-color: #f6f6e6 !important;
}
#modalCloseBtn {
  color: red;
}

.empDiv {
  /* width: 60%;
    margin: 0 auto; */
  text-align: left;
}

.empDataDiv {
  /* align-items: center; */
  /* justify-content: space-between; */
  /* text-align: center; */
  /* display: flex; */
  margin: 10px 0px;
}

.homeLogo {
  height: 80px;
  margin: 0 auto;
}

.empName {
  text-decoration: underline;
  color: #543379;
  font-weight: 500 !important;
  cursor: pointer;
}

.empQus {
  float: left;
  width: 50%;
  font-size: 16px;
  font-weight: 600;
  color: #4f4646;
}

.modalCloseBtn {
  display: block;
  position: absolute;
  right: 0;
  padding: 8px;
}

.modalFooterCloseBtn {
  display: block;
  float: right;
  padding: 0px 10px;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  width: 100px;
  height: 38px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #373a3c;
  border-radius: 5px;
}

.modalFooterDoneBtn {
  display: block;
  float: right;
  padding: 0px 10px;
  color: white;
  background-color: #41b5c2;
  border: 1px solid #41b5c2;
  width: 100px;
  height: 38px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.footer {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: #c4baba;
  font-weight: 700;
}

.side-navigation-panel *,
.side-navigation-panel:after,
.side-navigation-panel:before {
  border-color: #fff !important;
}

.side-navigation-panel-select-option {
  /* border-left-width: 4px !important; */
  color: #543379 !important;
  width: 100%;
}

.side-navigation-panel-select-inner-option {
  border-left-width: 0px !important;
  color: #543379 !important;
  background-color: #e5e5d5 !important;
  font-weight: 800 !important;
}

.side-navigation-panel-select-inner-option-selected {
  /* background-color: #e5e5d5 !important; */
  border-color: #fff !important;
  color: #543379 !important;
  background-color: #cdaccf !important;
  border-right: 5px solid #533279 !important;
}
.side-navigation-panel-select-inner-option-selected:hover {
  border-right: 5px solid #533279 !important;
}
.side-navigation-panel-select-option-selected {
  background-color: #f2f2e2 !important;
  border-color: #fff !important;
  color: #543379 !important;
  border-left-color: #fff !important;
  border-left-width: 0x !important;
}

nav {
  border-color: transparent !important;
  font-family: Roboto-Regular;
}

.side-navigation-panel-select-inner-option:hover {
  background-color: #cdaccf !important;
}

.side-navigation-panel-select-option-selected:hover {
  background-color: #e5e5d5 !important;
}

.side-navigation-panel-select-option:hover {
  background-color: #cdaccf !important;
  border-color: #fff !important;
}

/* .side-navigation-panel-select-inner-option:hover{
  border-color: #fff !important;
} */

.side-navigation-panel-select-option-text {
  font-weight: 800 !important;
}

.side-navigation-panel ul {
  border-bottom: 1px solid #e5e5d5 !important;
}

thead {
  background-color: #f4f5f6 !important;
}

#search-bar-0 {
  border: 1px solid #ccc;
  background-image: url("assets/images/icon.png");
  background-position: 180px 8px;
  background-color: #fff;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 20px;
  transition: width 0.4s ease-in-out;
  font-family: Roboto-Regular;
  min-width: 225px;
}

table {
  font-family: Roboto-Regular;
}

.react-bootstrap-table {
  margin-top: 15px;
}

.btn {
  font-size: 15px !important;
}

.searchbox {
  border: 1px solid #ccc;
  background-position: 180px 8px;
  background-color: #e4e5e6;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 20px;
  transition: width 0.4s ease-in-out;
  font-family: Roboto-Regular;
  min-width: 225px;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 10px 30px;
}

.contributionViewBtn {
  font-size: 15px;
  font-weight: 400;
  border-radius: 5px;
  background-color: #f5887f;
  color: white;
  border: 1px solid #f5887f;
  padding: 7px 20px 7px 20px;
  font-family: Roboto-Regular;
  min-width: 140px;
}

.contributionViewBtn:hover {
  background-color: #fdcf85;
  border: 1px solid #fdcf85;
  outline: 0px auto;
}

/* button {
  border-radius: 5px !important;
} */

.lg\:inset-0 {
  top: 66px !important;
}

.logoClsHeader {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  text-align: left;
  color: #fff;
  padding-top: 6px;
  padding-left: 20px;
}

.MuiButtonBase-root {
  /* color: black !important; */
}

.sidebarName {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.1px;
  text-align: left;
  color: #000;
}

.dropdown-header {
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.87) !important;
}

.dropdown-header:hover {
  background-color: #f2f2e2;
}
.MuiIconButton-root:hover {
  background-color: transparent !important;
}

/* .MuiInputBase-input:focus {
  outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE;
} */

.MuiIcon-fontSizeSmall {
  color: #787885;
}

.MuiInputAdornment-positionStart {
  margin-right: 8px;
  position: absolute;
  right: 25px;
}

.MuiFormControl-root {
  min-width: 150px;
  padding-left: 16px;
  position: absolute !important;
  left: 0;
  top: 9px;
}

.MuiInputBase-input {
  padding: 10px !important;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}
.MuiSelect-icon {
  right: -7px !important;
}
.MuiTabs-flexContainer {
  background-color: #f6f6e6;
}
.MuiBox-root {
  padding: 24px 0px !important;
  padding-bottom: 0px !important;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  padding: 4px !important;
}
.detailPanel {
  background-color: #f6f6f6;
  padding-right: 60px;
  padding-left: 60px;
}
.detailPanelTable {
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.detailPanelth {
  border: none !important;
  font-size: 12px;
  color: #454d58;
  padding-top: 10px;
}
.detailPaneltd {
  border: none !important;
  color: #000;
  width: 113px;
  height: 24px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #333;
  padding-top: 10px;
}
.detailPanelTableHeader {
  font-size: 16px;
  font-weight: 600;
  color: #454d58;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.exportImg {
  position: absolute !important;
  right: 80px !important;
  top: 70px !important;
  /* z-index: 1; */
  /* width:35px !important;;
  height:35px !important;; */
  /* padding-top:6px !important;; */
  /* border-radius: 20px !important;;
  background-color: #eb5757 !important;;
  font-size: small;
  color: white; */
}

.greenAgecls {
  color: green;
}
.yellowAgecls {
  color: orange;
}
.redAgecls {
  color: red;
}

/* .material-icons{
    color: white !important;
  } */

.panel-fullscreen {
  overflow: hidden;
  max-width: 100%;
}

.panel-fullscreen {
  position: fixed;
  z-index: 2055;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
}
